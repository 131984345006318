import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/Auth";

const Profile = () => {
  const navigate = useNavigate();
  const {userData,logout} = useAuth()
  const [username,setUsername] = useState(userData?.userdata?.fullname)
  const [email,setEmail] = useState(userData?.userdata?.email)


  const Logout = async () => {
    // Save token in local storage

    await logout()
  };
  return (
    <div className="w-full">
      <div className="flex md:flex-row flex-col w-full h-full justify-between items-start gap-10">
        {/* Profile Section */}
        <div className="bg-white md:w-[50%] w-full md:p-5 rounded-xl border border-slate-200 shadow shadow-slate-300 shadow-sm">
          <h2 className="text-xl font-semibold">Profile</h2>
          {/* profile edit */}

          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-3 mt-3">
              <label
                htmlFor=""
                className="text-sm tracking-wider text-slate-700 font-poppins"
              >
                Username
              </label>
              <input
              value={username}
              onChange={(e)=>setUsername(e.target.value)}
                type="text"
                placeholder="username"
                className="border border-slate-300 rounded-lg h-8 md:w-full w-full px-4 placeholder-slate-400 placeholder-text-xs"
              />
            </div>
            <div className="flex flex-col space-y-3 mt-3">
              <label
                htmlFor=""
                className="text-sm tracking-wider text-slate-700 font-poppins"
              >
                Email
              </label>
              <input
              value={email}
              onChange={(e)=>setEmail(e.target.value)}
                type="email"
                placeholder="email"
                className="border border-slate-300 rounded-lg h-8 md:w-full placeholder-slate-400 w-full px-4"
              />
            </div>
            
            
            <div className="flex flex-col space-y-3 mt-3">
              <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                Update
              </button>
            </div>
          </div>
        </div>

        {/* Password reset section */}
        <div className="bg-white md:w-[50%] w-full md:p-5 rounded-xl border border-slate-200 shadow shadow-slate-300 shadow-sm">
          <h2 className="text-xl font-semibold">Reset Password</h2>
          {/* profile edit */}

          <div className="flex flex-col space-y-4">
            
            <div className="flex flex-col space-y-3 mt-3">
              <label
                htmlFor=""
                className="text-sm tracking-wider text-slate-700 font-poppins"
              >
                New Password
              </label>
              <input
                type="password"
                placeholder="new password"
                className="border border-slate-300 rounded-lg h-8 md:w-full placeholder-slate-400 w-full px-4"
              />
            </div>
            <div className="flex flex-col space-y-3 mt-3">
              <label
                htmlFor=""
                className="text-sm tracking-wider text-slate-700 font-poppins"
              >
                Confirm Password
              </label>
              <input
                type="password"
                placeholder="confirm new password"
                className="border border-slate-300 rounded-lg h-8 placeholder-slate-400 md:w-full w-full px-4"
              />
            </div>

            <div className="flex flex-col space-y-3 mt-3">
              <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                Change Password
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white md:w-[50%] space-y-5 w-full md:p-5 rounded-xl border border-slate-200 shadow shadow-slate-300 shadow-sm">
          <h2 className="text-xl font-semibold">User Actions</h2>
          {/* profile edit */}

          <div className="flex md:flex-row justify-between items-center gap-10 flex-col">
            <div className="flex flex-col w-full">
              <button
                onClick={() =>
                  document.getElementById("my_modal_2").showModal()
                }
                className="bg-red-500 h-10 md:w-40 w-full rounded-xl text-white text-sm font-semibold"
              >
                Logout
              </button>

              {/* modal content */}
              <dialog id="my_modal_2" className="modal">
                <div className="modal-box">
                  <h3 className="font-bold text-lg">Hello {username}</h3>
                  <p className="py-4">Do you really want to Logout?</p>
                  <form
                    method="dialog"
                    className="flex justify-between items-center w-full"
                  >
                    <button className="bg-red-400 p-2 text-white rounded-md">
                      close
                    </button>
                    <button
                      onClick={Logout}
                      className="text-white bg-black rounded-md p-2"
                    >
                      Ok
                    </button>
                  </form>
                </div>
              </dialog>
              {/* end modal */}
            </div>
            
          </div>
        </div>

        {/* forgot password */}
      </div>
    </div>
  );
};

export default Profile;
