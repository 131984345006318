import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../utils/config";

const AddVehicle = () => {
  const [name, setName] = useState("");
  const [plate, setPlate] = useState("");
  const [seats, setSeats] = useState("");
  const [loading, setLoading] = useState(false);

  // const navigation = useNavigate()

  const addVehicle = async (e) => {
    setLoading(true)
    e.preventDefault();

    if (!name || !plate || !seats) {
      e.preventDefault();
      console.log("All fields are required");
      toast.error("Kindly fill all the inputs");
      setLoading(false);
      return;
    }

    // const formData = new FormData();
    // for (let i = 0; i < file.length; i++) {
    //     formData.append('file', file[i]);
    // }

    // formData.append('productname', productname);
    // formData.append('price', price);
    // formData.append('description', description);

    try {
      const response = await axios.post(
        // "http://localhost:5000/api/v1/admin/createvehicle",
        `${BASE_URL}/createvehicle`,
        // "https://lipabooking.vercel.app/api/v1/admin/createvehicle",
        {
          name,
          plate,
          seats,
        }
      );
      // console.log(response);
      console.log("Vehicle added successfully");
      toast.success("Vehicle added successfully");
      // Reset form fields after successful submission
      setName("");
      setPlate("");
      setSeats("");
      setLoading(false);
      // Optionally navigate to another page after successful submission
      // navigation('/dashboard');
    } catch (error) {
      console.log("Error adding new vehicle");
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className="w-full">
      <div className="flex md:flex-row flex-col w-full h-full justify-between items-start gap-10">
        {/* Profile Section */}
        <div className="bg-white md:w-[50%] w-full md:p-5 rounded-xl border border-slate-200 shadow shadow-slate-300 shadow-sm">
          <h2 className="text-xl font-semibold">Add Vehicle</h2>
          {/* profile edit */}

          <div className="flex flex-col space-y-4">
            <form action="" onSubmit={addVehicle} encType="multipart/form-data">
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Username
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Vihicle Name"
                  className="border border-slate-300 rounded-lg h-8 md:w-full w-full px-4 placeholder-slate-400 placeholder-text-xs"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Number Plate/Reg
                </label>
                <input
                  type="text"
                  placeholder="Registration number"
                  value={plate}
                  onChange={(e) => setPlate(e.target.value.toUpperCase())}
                  className="border border-slate-300 rounded-lg h-8 md:w-full placeholder-slate-400 w-full px-4"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Number of seats
                </label>
                <input
                  type="text"
                  value={seats}
                  onChange={(e) => setSeats(e.target.value)}
                  placeholder="Number of seats"
                  className="border border-slate-300 rounded-lg h-8 placeholder-slate-400 md:w-full w-full px-4"
                />
              </div>

              <div className="flex flex-col space-y-3 mt-3">
                {loading ? (
                  <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                    Adding Vehicle....
                  </button>
                ) : (
                  <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                    Add Vehicle
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        {/* forgot password */}
      </div>
    </div>
  );
};

export default AddVehicle;
