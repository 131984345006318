import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import Divider from "@mui/material/Divider";

import { navLinks } from "../constants";
import { Link } from "react-router-dom";

export default function TemporaryDrawer({ open, setOpen }) {
  const toggleDrawer = () => () => {
    setOpen(!open);
  };

  const DrawerList = (
    <Box
      className="bg-white w-80"
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div className="w-full px-4 space-y-4">
        <div>
          <h5 className="font-bold">Menu</h5>
        </div>
        <ul className="list-none items-center md:flex justify-end flex-1">
          {navLinks.map((nav, index) => (
            <li
              key={index}
              className={`font-poppins font-normal cursor-pointer text-[16px]
          ${
            index === navLinks.length - 1 ? "mr-0" : "mr-10"
          } text-white mr-2 tracking-wide
          `}
            >
              <Link
                to={`${nav.path}`}
                className="text-slate-500 font-normal px-3 py-2 rounded-md text-sm"
              >
                {nav.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Divider />
    </Box>
  );

  return (
    <div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
