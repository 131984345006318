import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, USER_URL } from "../utils/config";

const Recents = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userMap, setUserMap] = useState({});

  // Function to format date to remove time
  const formatDate = (date) => {
    const d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  };

  // Fetch recent bookings (yesterday and today)
  const getRecentBookings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/allbookings`
        // "https://lipabooking.vercel.app/api/v1/admin/allbookings"
      );
      const data = response.data;

      // Calculate yesterday and today dates
      const today = formatDate(new Date());
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      // Filter and sort bookings
      const recentBookings = data
        .filter((booking) => {
          const bookingDate = formatDate(booking.bookingDate);
          return bookingDate >= yesterday && bookingDate <= today;
        })
        .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate));

      setBookings(recentBookings);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching recent bookings");
      setLoading(false);
    }
  };

  // Fetch user data and create a user map
  const getUserdata = async () => {
    try {
      const response = await axios.get(
        `${USER_URL}/allUsers`
        // `https://lipabooking.vercel.app/api/v1/user/allUsers`
      );
      const users = response.data;

      // Create a map of userId to username
      const userMap = users.reduce((map, user) => {
        map[user._id] = user.username; // Assuming each user object has _id and username fields
        return map;
      }, {});

      setUserMap(userMap);
    } catch (error) {
      console.log(error);
      toast.error("Error fetching user data");
    }
  };

  useEffect(() => {
    getRecentBookings();
    getUserdata();
  }, []);

  return (
    <div className="w-full h-full p-5 space-y-4">
      {loading ? (
        <div className="w-full h-full justify-center flex items-center">
          <p>Loading....</p>
        </div>
      ) : (
        <div>
          <div>
            <p className="font-poppins font-bold text-xl tracking-wide">
              Recent Bookings
            </p>
            <p className="text-slate-400 text-xs tracking-wide">
              You received several bookings this month.
            </p>
          </div>

          {bookings.length > 0 ? (
            bookings.slice(0, 10).map((recent) => {
              return (
                <div
                  key={recent._id}
                  className="w-full flex justify-between items-center space-y-10 "
                >
                  <div className="flex justify-between items-center space-x-4">
                    <div>
                      <div className="border border-slate-500 justify-center items-center flex rounded-full h-10 w-10">
                        <img
                          src="../images/booking.png"
                          className="h-6 w-6"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-sm tracking-wide">
                        {userMap[recent.userId] || "Unknown User"}
                      </p>
                      <p className="text-slate-500 text-xs tracking-wide">
                        {recent.seats.join(",")}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="text-lg font-normal tracking-wide text-slate-600">
                      +{recent.price}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full h-full flex flex-col space-y-5 justify-center items-center p-10">
              <img src="../images/booking.png" className="h-12 w-12" alt="" />
              <p className="text-sm text-slate-400 tracking-wide">
                No recent bookings found.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Recents;
