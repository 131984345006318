import React, { useState } from "react";
import Navbar from "../components/Navbar";
import NewVehicles from "../components/NewVehicles";

const NewVehicle = () => {
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(!open);
  };

  return (
    <div className="flex-1 w-full h-screen overflow-hidden bg-primary overflow-y-scroll">
      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          <Navbar open={open} setOpen={setOpen} show={show} />
        </div>
      </div>
      <div className="flex sm:px-6 px-6 justify-center items-center">
        <div className="w-full xl:max-w-[1280px]">
          <NewVehicles open={open} setOpen={setOpen} show={show} />
        </div>
      </div>
    </div>
  );
};

export default NewVehicle;
