export const navLinks = [
    {
      id: "dashboard",
      name: "Dashboard",
      path: "/",
      icon: "Home",
    },
    {
      id: "usermgt",
      name: "User mgt.",
      path: "/user",
      icon: "Home",
    },
    {
      id: "tripmgt",
      name: "Trip mgt.",
      path: "/trip",
      icon: "Home",
    },
    {
      id: "destinationmgt",
      name: "Destination mgt.",
      path: "/destination",
      icon: "Home",
    },
    {
      id: "vehiclemgt",
      name: "Vehicle mgt.",
      path: "/vehicle",
      icon: "Home",
    },
    {
      id: "drivermgt",
      name: "Driver mgt.",
      path: "/driver",
      icon: "Home",
    },
    {
      id: "bookingmgt",
      name: "Bookings",
      path: "/bookings",
      icon: "Home",
    },
    {
      id: "settings",
      name: "Settings",
      path: "/settings",
      icon: "Home",
      
    },
  ];



  export const analytics = [
    {
      id:1,
      title:"Total Drivers",
      value:"4,523,189",
      percentage:"20% from last month",
      icon: "User",
      function:"drivers"
    },
    {
      id:1,
      title:"Bookings",
      value:"22",
      percentage:"18% from last month",
      icon: "Database",
      function:"bookings"
    },
    {
      id:1,
      title:"Total Users",
      value:"12,234",
      percentage:"19% from last month",
      icon: "Users",
      function:"users"
    },
    {
      id:1,
      title:"Total Vehicles",
      value:"5",
      percentage:"201 from last month",
      icon: "Truck",
      function:"vehicles"
    },

  ]



  export const recents = [
    {
      id:1,
      name:"Olivia Martin",
      email:"jimmy@gmail.com",
      amount:"1,999.00"
    },
    {
      id:2,
      name:"Olivia Martin",
      email:"jimmy@gmail.com",
      amount:"1,999.00"
    },
    {
      id:3,
      name:"Olivia Martin",
      email:"jimmy@gmail.com",
      amount:"1,999.00"
    },
    {
      id:4,
      name:"Olivia Martin",
      email:"jimmy@gmail.com",
      amount:"1,999.00"
    },
    {
      id:5,
      name:"Olivia Martin",
      email:"jimmy@gmail.com",
      amount:"1,999.00"
    },
    {
      id:6,
      name:"Olivia Martin",
      email:"jimmy@gmail.com",
      amount:"1,999.00"
    },
  ]