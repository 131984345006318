import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/config";

const AddTrip = () => {
  const [loading, setLoading] = useState(false);
  // const navigate = useNavigate();
  const [locations, setLocations] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [error, setError] = useState(null);
  const [departure, setDeparture] = useState("");
  const [destination, setDestination] = useState("");
  const [tripdate, setTripDate] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [leavingTime, setLeavingTime] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [price, setPrice] = useState("");

  // Fetch locations
  useEffect(() => {
    axios
      // .get("https://lipabooking.vercel.app/api/v1/admin/alldestinations")
      .get(`${BASE_URL}/alldestinations`)
      .then((response) => {
        setLocations(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  

  const getAvailableCars = async () => {
    try {
      const response = await axios
        .get(`${BASE_URL}/getavailablevehicles`)
        // .get("https://lipabooking.vercel.app/api/v1/admin/getvehicles")
        .then((response) => {
          setVehicles(response.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error fetching available vehicles");
      setLoading(false);
    }
  };
  // Fetch vehicles
  useEffect(() => {
    getAvailableCars();
  }, []);

  const addTrip = async (e) => {
    // console.log("date of trip",tripdate)
    e.preventDefault();
    setLoading(true);

    if (
      !departure ||
      !destination ||
      !selectedVehicle ||
      !leavingTime ||
      !arrivalTime ||
      !price ||
      !tripdate
    ) {
      toast.error("Kindly fill all the inputs");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/createtrip`,
        // "https://lipabooking.vercel.app/api/v1/admin/createtrip",
        {
          departure,
          destination,
          vehicleId: selectedVehicle,
          leavingTime,
          arrivalTime,
          price,
          tripdate,
        }
      );
      // console.log(response.data);
      // console.log("Trip added successfully");
      toast.success("Trip added successfully");
      setLoading(false);
      // Reset form fields after successful submission
      setDeparture("");
      setDestination("");
      setSelectedVehicle("");
      setLeavingTime("");
      setArrivalTime("");
      setPrice("");
      setTripDate("");
      getAvailableCars()
      // Optionally navigate to another page after successful submission
      // navigate('/dashboard');
    } catch (error) {
      console.log("Error adding new trip");
      console.log(error.message);
      if ((error.message = "Request failed with status code 400")) {
        toast.error("Vehicle already assigned trip");
      }
      toast.error("Error adding trip");
      setLoading(false);
    }
  };
  return (
    <div className="w-full">
      <div className="flex md:flex-row flex-col w-full h-full justify-between items-start gap-10">
        {/* Profile Section */}
        <div className="bg-white md:w-[50%] w-full md:p-5 rounded-xl border border-slate-200 shadow shadow-slate-300 shadow-sm">
          <h2 className="text-xl font-semibold">Add Trip</h2>
          {/* profile edit */}

          <div className="flex flex-col space-y-4">
            <form action="" onSubmit={addTrip} encType="multipart/form-data">
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  From
                </label>
                <select
                  id="departure"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={departure}
                  onChange={(e) => setDeparture(e.target.value)}
                >
                  <option value="" disabled selected>
                    Choose departure location
                  </option>
                  {locations.map((loc, index) => (
                    <option key={index} value={loc.location}>
                      {loc.location}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  To
                </label>
                <select
                  id="destination"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                >
                  <option value="" disabled selected>
                    Choose destination
                  </option>
                  {locations.map((loc, index) => (
                    <option key={index} value={loc.location}>
                      {loc.location}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Choose Vehicle
                </label>
                <select
                  id="vehicle"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={selectedVehicle}
                  onChange={(e) => setSelectedVehicle(e.target.value)}
                >
                  <option value="" disabled selected>
                    Choose a vehicle
                  </option>
                  {vehicles.map((car, index) => (
                    <option key={index} value={car._id}>
                      {car.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Choose Leaving Time
                </label>
                <select
                  id="leavingTime"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={leavingTime}
                  onChange={(e) => setLeavingTime(e.target.value)}
                >
                  <option value="" disabled selected>
                    Choose leaving time
                  </option>
                  <option value="7:45 A.M">7:45 A.M</option>
                  <option value="8:00 A.M">8:00 A.M</option>
                  <option value="9:00 A.M">9:00 A.M</option>
                  <option value="7:45 P.M">7:45 P.M</option>
                  <option value="8:00 P.M">8:00 P.M</option>
                  <option value="9:00 P.M">9:00 P.M</option>
                </select>
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Choose Arrival Time
                </label>
                <select
                  id="arrivalTime"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={arrivalTime}
                  onChange={(e) => setArrivalTime(e.target.value)}
                >
                  <option value="" disabled selected>
                    Choose arrival time
                  </option>
                  <option value="5:45 A.M">5:45 A.M</option>
                  <option value="8:00 A.M">8:00 A.M</option>
                  <option value="9:00 A.M">9:00 A.M</option>
                  <option value="7:45 P.M">7:45 P.M</option>
                  <option value="8:00 P.M">8:00 P.M</option>
                  <option value="9:00 P.M">9:00 P.M</option>
                </select>
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins"
                >
                  Select Trip Date
                </label>

                <input
                  datepicker
                  type="date"
                  value={tripdate}
                  onChange={(e) => setTripDate(e.target.value)}
                  class="bg-white rounded-md border border-gray-300 placeholder-slate-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select date"
                />
              </div>
              <div className="flex flex-col space-y-3 mt-3">
                <label
                  htmlFor=""
                  className="text-sm tracking-wider text-slate-700 font-poppins "
                >
                  Enter Trip Price
                </label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="text-sm border border-slate-300 rounded-md tracking-wider placeholder-slate-400 text-slate-700 font-poppins"
                  placeholder="Enter price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </div>

              <div className="flex flex-col space-y-3 mt-3">
                <button className="bg-black md:w-full w-full rounded-xl h-10 text-white">
                  Create Trip
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* forgot password */}
      </div>
    </div>
  );
};

export default AddTrip;
