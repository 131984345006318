import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../utils/config';
import { toast } from 'react-toastify';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [userData, setUserData] = useState(null);

  // Fetch user data using the token
  const fetchUserData = async (token) => {
    try {
      const response = await axios.post(`${BASE_URL}/admindata`, { token });
      const userData = response.data;
      setUserData(userData);
      // console.log("driverdata",userData)

      // Store userData in localStorage
      // localStorage.setItem('userdata', JSON.stringify(userData));
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Check login status on app load
  useEffect(() => {
    const checkLoginStatus = async () => {
      setLoading(true);

      const token = localStorage.getItem('token');
      const storedIsLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));

      if (token && storedIsLoggedIn) {
        setIsLoggedIn(true);
        setUserToken(token);

        // Fetch user data if token exists
        await fetchUserData(token);
      } else {
        // Clear any invalid login data
        logout();
      }

      setLoading(false);
    };

    checkLoginStatus();
  }, []);

  // Login function
  const login = async (email, password) => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/adminlogin`, { email, password });
      const { status, data } = response.data;

      if (status === 'ok' && data) {
        // Save token and login status to localStorage
        localStorage.setItem('token', data);
        localStorage.setItem('isLoggedIn', JSON.stringify(true));

        setUserToken(data);
        setIsLoggedIn(true);

        // Fetch and store user data
        await fetchUserData(data);
      } else {
        console.log('Login failed, no token returned');
        toast.error("Login failed.Check credentials.")
      }
    } catch (error) {
      console.error('Login error:', error.response.data.message);
      toast.error(error.response.data.message)
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('isLoggedIn');
    // localStorage.removeItem('userdata');

    setUserToken(null);
    setUserData(null);
    setIsLoggedIn(false);
    toast.success("Logged out successfully.")
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, setLoading,loading, userToken, userData, login, logout,fetchUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
