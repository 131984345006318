import axios from "axios";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/Auth";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [email, setEmail] = useState("rayomaina@gmail.com");
  // const [password, setPassword] = useState("123456");
  
  // const [loading,setLoading] = useState(false)
  
  const { login, isLoggedIn,loading,setLoading } = useAuth();

  if (isLoggedIn) return <Navigate to="/" />;

  const handleLogin = async(e) => {
    e.preventDefault();
    setLoading(true);
    await login(email, password);
  };

  
  return (
    <div className="w-full h-screen justify-center items-center flex">
      <div class="md:w-[50%] w-full border border-slate-300 rounded-lg shadow shadow-lg max-w-full px-3 mx-auto mt-0 md:flex-0 shrink-0">
        <div class="relative z-0 flex flex-col min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
          <div class="p-6 mb-0 text-center bg-white border-b-0 rounded-t-2xl">
            <h5 className="text-2xl font-bold tracking-[5px]">Login</h5>
          </div>
          
          <div class="flex-auto p-6">
            <form role="form text-left" onSubmit={handleLogin}>
              {/* <div class="mb-4">
                <input
                  aria-describedby="email-addon"
                  aria-label="Name"
                  placeholder="Name"
                  class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                  type="text"
                />
              </div> */}
              <div class="mb-4">
                <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                  aria-describedby="email-addon"
                  aria-label="Email"
                  placeholder="Email"
                  class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                  type="email"
                />
              </div>
              <div class="mb-4">
                <input
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                aria-describedby="password-addon"
                  aria-label="Password"
                  placeholder="Password"
                  class="text-sm focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                  type="password"
                />
              </div>
              

              <div class="text-center">
                <button
                  class="inline-block w-full px-6 py-3 mt-6 mb-2 font-bold text-center text-white uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer active:opacity-85 hover:scale-102 hover:shadow-soft-xs leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 bg-gradient-to-tl from-gray-900 to-slate-800 hover:border-slate-700 hover:bg-slate-700 hover:text-white"
                  type="submit"
                >
                  {
                    loading ? 'Authenticating...':'Sign In'
                  }
                </button>
              </div>
              <p class="mt-4 mb-0 leading-normal text-sm">
                Don't have an account?{" "}
                <a
                  class="font-bold text-slate-700"
                  href="/register"
                >
                  Sign Up
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
