import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SettingsPage from "./pages/SettingsPage";
import NewUser from "./pages/NewUser";
import NewTrip from "./pages/NewTrip";
import NewDestination from "./pages/NewDestination";
import NewDriver from "./pages/NewDriver";
import NewVehicle from "./pages/NewVehicle";
import BookingsPage from "./pages/BookingsPage";
import RegisterPage from "./pages/RegisterPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRoutes } from "./context/PrivateRoutes";

function App() {
  const isLoggedIn = localStorage.getItem("isLoggedin") === "true";

  console.log("The current login state:", isLoggedIn);

  return (
    <div>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/user" element={<NewUser />} />
          <Route path="/trip" element={<NewTrip />} />
          <Route path="/destination" element={<NewDestination />} />
          <Route path="/driver" element={<NewDriver />} />
          <Route path="/vehicle" element={<NewVehicle />} />
          <Route path="/bookings" element={<BookingsPage />} />
        </Route>

        <Route path="*" element={<div>404 - Not Found</div>} />
      </Routes>

      <ToastContainer />
    </div>
  );
}

export default App;
