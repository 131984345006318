import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Icon from "react-feather";
import { BASE_URL } from "../utils/config";

const DriverTable = () => {
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getAllDrivers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/alldrivers`
        // "https://lipabooking.vercel.app/api/v1/admin/alldrivers"
      );
      if (response) {
        setDrivers(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error fetching drivers");
      setLoading(false);
    }
  };

  const getAllVehicles = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/getvehicles`
        // "https://lipabooking.vercel.app/api/v1/admin/getvehicles"
      );
      if (response) {
        setVehicles(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error fetching vehicles");
    }
  };

  const updateVehicleStatus = async (vehicleId) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/updatevehicle/${vehicleId}`,
        // `https://lipabooking.vercel.app/api/v1/admin/updatevehicle/${vehicleId}`,
        {
          hasdriver: false, // Set hasdriver to false when a driver is deleted
        }
      );
      console.log(response.data);
      toast.success("Vehicle status updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error updating vehicle status");
    }
  };

  // delete user from the database
  const deleteDriver = async (id, vehicleId) => {
    const result = await Swal.fire({
      title: "Do you really want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(
          `${BASE_URL}/deletedriver/${id}`
          // `https://lipabooking.vercel.app/api/v1/admin/deletedriver/${id}`
        );
        toast.success("Driver deleted successfully");
        await updateVehicleStatus(vehicleId);
        getAllDrivers();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const downloadDriverCSV = () => {
    const csvContent = [
      ["Name", "Email", "Service Number", "Vehicle Name", "Join Date"],
      ...filteredDrivers.map((driver) => [
        driver.username,
        driver.email,
        driver.service,
        getVehicleNameById(driver.vehicleId),
        formatDate(driver.createdAt),
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "drivers_report.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getAllDrivers();
    getAllVehicles();
  }, []);

  const getVehicleNameById = (vehicleId) => {
    const vehicle = vehicles.find((v) => v._id === vehicleId);
    return vehicle ? vehicle.name : "Unknown Vehicle";
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const filteredDrivers = drivers.filter((driver) =>
    [driver.username, driver.email, driver.service]
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;


  //add pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDrivers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredDrivers.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  

  return (
    <div className="w-full">

      {/* update modal */}
      <dialog id="my_modal_2" className="modal">
                <div className="modal-box">
                  <h3 className="font-bold text-lg">Hello User</h3>
                  <p className="py-4">
                    Press ESC key or click outside to close
                  </p>
                  <form
                  method="dialog"
                  className="flex justify-between items-center w-full"
                >
                  <button className="bg-red-400 p-2 text-white rounded-md">
                    close
                  </button>
                  <button className="text-white bg-black rounded-md p-2">
                    Ok
                  </button>
                </form>
                </div>
                
              </dialog>
      <div className="w-full justify-end items-end flex my-5">
        <div className="flex items-center space-x-4">
          <div>
            <div class="relative max-w-sm">
              <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <input
                id="datepicker-format"
                
                type="text"
                placeholder="Search drivers..."
                value={searchTerm}
                onChange={handleSearchChange}
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                
              />
            </div>
          </div>
          <div>
            <button
              onClick={downloadDriverCSV}
              className="bg-black flex space-x-2 items-center text-white text-sm py-2 px-2 rounded-md"
            >
              Download
              <Icon.Download color="white" size={15} />
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Email
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Service Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Vehicle Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Join Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                  {currentItems.map((driver) => {
                    return (
                      <tr key={driver._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">
                          {driver.username}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                          {driver.email}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                          {driver.service}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                          {driver.vehicleId.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                          {formatDate(driver.createdAt)}
                        </td>
                        <td className="px-6 space-x-4 flex items-end justify-end py-4 whitespace-nowrap text-end text-sm font-medium">
                          <button
                            onClick={() =>
                              document.getElementById("my_modal_2").showModal()
                            }
                            type="button"
                            className="inline-flex items-center gap-x-2 text-sm bg-black p-1 rounded-md font-normal rounded-lg border border-transparent text-white focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none "
                          >
                            Edit
                          </button>
                          <button
                            onClick={() =>
                              deleteDriver(driver._id, driver.vehicleId)
                            }
                            type="button"
                            className="inline-flex items-center gap-x-2 text-sm font-normal bg-red-500 p-1 rounded-lg border border-transparent text-white hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center mt-4">
        <span class="text-sm text-gray-700 dark:text-gray-400">
          Showing{" "}
          <span class="font-semibold text-gray-900 dark:text-white">
            {currentPage}
          </span>{" "}
          of{" "}
          <span class="font-semibold text-gray-900 dark:text-white">
            {totalPages}
          </span>{" "}
          Pages
        </span>

        <div class="inline-flex mt-2 xs:mt-0">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            class={`flex items-center justify-center px-4 h-10 text-base font-medium text-white disbled:bg-slate-400 bg-gray-800 rounded-s hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
          >
            Prev
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            class="flex items-center justify-center px-4 h-10 text-base font-medium text-white bg-gray-800 border-0 border-s border-gray-700 rounded-e hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default DriverTable;
